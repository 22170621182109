<template>
  <div>
    <span
      class="btn btn-gray btn-primary"
      @click="openNewFileDialog"
    > Add File</span>
    <b-modal
      id="modal-lg"
      ref="add_new_file_modal"
    >
      <vue-dropzone
        id="dropzone"
        ref="uploadFileDropzone"
        class="dropzone-container"
        :use-custom-slot="false"
        :options="dropzoneOptions"
      />

      <b-row
        no-gutters
        class="pt-1"
      >
        The file belongs to:
        <b-col cols="12">
          <b-form-select
            v-model="selected"
            class="form-select-scooter-type"
            :options="options"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
          />
        </b-col>
      </b-row>

      <template #modal-footer>
        <button
          class="btn btn-primary"
          @click="sendingFiles"
        >
          Upload
        </button>
        <button
          class="btn btn-secondary"
          @click="closeModel"
        >
          Close
        </button>
      </template>

    </b-modal>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    options: {
      type: Array,
      default() {
        return Array
      },
    },
  },
  data() {
    return {
      selected: [],
      dropzoneOptions: {
        url: 'No link',
        dictDefaultMessage: 'Drop files here or click to upload',
        includeStyling: false,
        thumbnailWidth: '250',
        thumbnailHeight: '250',
        parallelUploads: 20,
        acceptedFiles: '.db3',
        thumbnailMethod: 'crop',
        addRemoveLinks: true,
        previewsContainer: '.dropzone-container',
        autoProcessQueue: false,
      },
      uploadProgress: 0,
    }
  },
  mounted() {
  },
  methods: {
    saveForm() {
      if (this.$refs.uploadFileDropzone.getQueuedFiles().length) {
        this.$refs.uploadFileDropzone.processQueue()
      } else {
        this.submitForm()
      }
    },
    sendingFiles() {
      const file = this.$refs.uploadFileDropzone.getQueuedFiles()
      const formData = new FormData()
      formData.append('scooter_type', this.selected)
      formData.append('file', file[0])
      this.$http.post(this.$resources.firmwares.store, formData).then(() => {
        this.$emit('addNewFile', this.selected)
        this.$refs.add_new_file_modal.hide()
      }).catch(err => {
        let validationErrors = ''
        if (err.response.data.file) {
          validationErrors += `- ${err.response.data.file[0]}`
        }
        if (err.response.data.scooter_type) {
          if (validationErrors !== '') {
            validationErrors += '\n'
          }
          validationErrors += `- ${err.response.data.scooter_type[0]}`
        }
        this.$notify({ type: 'danger', title: 'Validation error', text: validationErrors })
      })
    },
    openNewFileDialog() {
      this.$refs.add_new_file_modal.show()
    },
    closeModel() {
      this.$refs.add_new_file_modal.hide()
    },
  },

}
</script>
