<template>
  <b-col
    lg="3"
    sm="3"
    cols="12"
    class="email-template"
  >
    <div
      class="template-container"
      @click="openTemplatePopup"
    >
      <b-row class="template-icon-holder">
        <feather-icon
          icon="MailIcon"
          size="40"
        />
      </b-row>
      <b-row
        class="template-text-holder"
        no-gutters
      >
        <b-col>
          <b-row
            class="email-template-title"
            no-gutters
          >
            {{ template.title }}
          </b-row>
          <b-row
            class="email-template-subject"
            no-gutters
          >
            {{ template.subject }}
          </b-row>
        </b-col>
      </b-row>
    </div>
    <b-modal
      ref="add_new_file_modal"
      header-class="template-modal-header"
      modal-class="template-modal"
      hide-footer
    >

      <template
        #modal-header
      >

        <b-row>
          <b-col cols="10">
            <span
              v-if="!editTemplateMode"
              class="title-holder col-12"
            >{{ template.title }}</span>
            <b-input
              v-else
              v-model="templateTitle"
              class="col-10"
              style="display: initial"
            />
          </b-col>
          <b-col cols="2">
            <span
              v-if="!editTemplateMode"
              class="d-block edit-save-button"
              @click="editTemplateMode = !editTemplateMode"
            >Edit</span>
            <span
              v-if="editTemplateMode"
              class="d-block edit-save-button"
              @click="saveTemplate"
            >Save</span>
          </b-col>
        </b-row>

      </template>

      <span class="template-key">
        #{{ template.key }}
      </span>

      <b-row
        no-gutters
        class="template-subject"
      >
        <b-col
          cols="12"
        >
          <label class="template-subject-title">
            Subject:
          </label>
          <!-- <span class="col-12 template-subject">#subject_from_gorgias_here</span> -->
          <span
            v-if="!editTemplateMode"
            class="col-10"
          >#{{ template.subject }}</span>
          <b-input
            v-else
            v-model="templateSubject"
            class="col-10"
            style="display: initial"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="!editTemplateMode"
        class="template-content mt-1"
        no-gutters
        v-html="template.body"
      />

      <b-row
        v-else
        class="template-content mt-1"
        no-gutters
      >

        <editor
          ref="templateEditor"
          v-model="editorData"
          :api-key="apiKey"
          :init="editorInit"
        />

      </b-row>

    </b-modal>

  </b-col>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    Editor,
  },
  props: {
    template: {
      type: Object,
      default() {
        return Object
      },
    },
    indexKey: {
      type: Number,
      default() {
        return Number
      },
    },
  },
  data() {
    return {
      // editor: ClassicEditor,
      apiKey: 'flx4kmhowso1ueq2ez2pyr74nw4claz5rqb5g5oaw2wrnv7a',
      editTemplateMode: false,
      editorData: '',
      editorInit: {
        height: 500,
        width: 500,
        menubar: true,
        file_picker_types: 'image',
        file_picker_callback: cb => {
          const input = document.createElement('input')
          input.setAttribute('type', 'file')
          input.setAttribute('accept', 'image/*')
          input.onchange = () => {
            const file = input.files[0]
            const reader = new FileReader()
            reader.onload = () => {
              const id = `blobid${(new Date()).getTime()}`
              const { blobCache } = this.$refs.templateEditor.editor.editorUpload
              const base64 = reader.result.split(',')[1]
              const blobInfo = blobCache.create(id, file, base64)
              blobCache.add(blobInfo)
              cb(blobInfo.blobUri(), { title: file.name })
            }
            reader.readAsDataURL(file)
          }
          input.click()
        },
        plugins: [
          'advlist', 'code', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
          'anchor', 'searchreplace', 'visualblocks', 'fullscreen', 'insertdatetime', 'media',
          'table', 'help', 'wordcount',
        ],
        toolbar:
        `undo redo link code table | casechange blocks | bold italic forecolor| 
        alignleft aligncenter alignright alignjustify | 
        bullist numlst checklist outdent indent | removeformat | help`,
        // `undo redo table | casechange blocks | bold italic forecolor image|
        // alignleft aligncenter alignright alignjustify |
        // bullist numlst checklist outdent indent | removeformat | a11ycheck code table help`,

      },
      // customToolbar: [
      //   [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      //   ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      //   [
      //     { align: '' },
      //     { align: 'center' },
      //     { align: 'right' },
      //     { align: 'justify' },
      //   ],
      //   ['blockquote', 'code-block'],
      //   [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      //   [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      //   [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      //   ['link', 'image', 'table'],
      //   ['clean'], // remove formatting button
      // ],
    }
  },
  mounted() {
    this.editorData = this.template.body
    this.templateSubject = this.template.subject
    this.templateTitle = this.template.title

    document.addEventListener('focusin', event => {
      event.stopImmediatePropagation()
    }, false)
  },
  methods: {
    openTemplatePopup() {
      this.$refs.add_new_file_modal.show()
    },
    saveTemplate() {
      const updatedTemplate = {
        subject: this.templateSubject,
        title: this.templateTitle,
        body: this.editorData,
      }

      this.$http.post(this.$resources.emailTemplates.update.replace(':id', this.template.id), updatedTemplate).then(() => {
        this.editTemplateMode = false
        this.$emit('updateTemplate', this.indexKey, updatedTemplate)
        this.$refs.add_new_file_modal.hide()
      })
    },
  },
}
</script>

<style lang="scss">
 .email-template {
   font-family: Montserrat;
   margin-bottom: 10px;
   .template-container {
     cursor: pointer;
     border-radius: 8px;
     border: 2px solid #E4E4E4;
     background-color: #f8f8f8;
     .template-icon-holder {
       display: flex;
       align-items: center;
       justify-content: center;
       height: 120px;
       color: #0F0F10;
      }
      .template-text-holder {
        background-color: #fff;
        border-radius: 0  0 8px 8px;
            padding-left: 16px;
            .email-template-title {
               padding-top: 10px;
               font-size: 14px;
               line-height: 24px;
               font-weight: 500;
              }
            .email-template-subject {
                padding-bottom: 10px;
                color: #B4B4B4;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
            }
        }
     }
    }
    .tox-notifications-container {
      display: none !important;
    }
    .modal-open .modal {
      .template-modal-header {
          margin-top: 15px;
          display: block;
          background-color: #fff !important;
          width: 100% !important;
     }

       .template-subject {
          padding: 10px 0;
          border-top:1px solid #DCDCDC;
          border-bottom:1px solid #DCDCDC;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          color: #808080;
       }

       .template-subject {
        margin-left: -10px !important;
       }

       .template-subject-title {
            height: 21px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            color: #1D1D1D;
            margin-right: 10px;
       }

       .template-key {
          background: rgba(0, 154, 221, 0.12);
          border-radius: 4px;
          margin-top: 10px;
          width: fit-content;
          display: block;
          font-weight: bold;
          color: #009ADD;
          line-height: 18px;
          font-weight: 500;
          margin-top: -10px !important;
          margin-bottom: 10px;
          display: block;
          padding: 5px;
          background: rgba(0, 154, 221, 0.12);
          border-radius: 4px;
          margin-top: -10px !important;
          margin-bottom: 10px;
          padding: 2px 9px;
          font-size: 13px;
       }

       .title-holder {
        margin-left: -15px !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 21px;
        text-align: center;
        color: #000000;
       }

       .edit-save-button {
          cursor: pointer;
          width: 33px;
          height: 21px;
          font-family: 'tahoma';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          text-align: center;
          color: #808080;
       }

       .form-control {
        height: 27px;
       }

    }
    </style>
