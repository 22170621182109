<template>
  <div class="configurator-page">
    <files-list />
    <templates-row />
  </div>
</template>

<script>
import FilesList from '@/views/settings/components/FilesList.vue'
import TemplatesRow from '@/views/settings/components/TemplatesRow.vue'

export default {
  components: {
    FilesList,
    TemplatesRow,
  },
}
</script>

<style lang="scss">

</style>
