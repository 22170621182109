<template>
  <b-row>
    <b-col>
      <b-card>
        <b-row no-gutters>
          <p class="page-title">
            Email Templates
          </p>
        </b-row>
        <b-row>
          <email-template
            v-for="(email_template,index) in emailTemplates"
            :key="index"
            :index-key="index"
            :template="email_template"
            @updateTemplate="updatedTemplate"
          />
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import EmailTemplate from '@/views/settings/components/EmailTemplate.vue'

export default {
  components: {
    EmailTemplate,
  },
  data() {
    return {
      emailTemplates: [],
    }
  },

  mounted() {
    this.getEmailTemplates()
  },

  methods: {
    getEmailTemplates() {
      this.$http.get(this.$resources.emailTemplates.index).then(response => {
        const { data: { data } } = response
        this.emailTemplates = data.rows
      })
    },
    updatedTemplate(index, updatedTemplate) {
      this.emailTemplates[index].subject = updatedTemplate.subject
      this.emailTemplates[index].title = updatedTemplate.title
      this.emailTemplates[index].body = updatedTemplate.body
    },
  },

}
</script>

<style>

</style>
