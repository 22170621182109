<template>
  <b-card class="configurator-files">
    <b-row>
      <b-col
        cols="12"
        lg="8"
      >
        <p class="page-title">
          Firmware Files
        </p>
      </b-col>
      <b-col>
        <b-row>
          <b-col cols="5">
            <add-new-file
              :options="optionsWithout('All')"
              @addNewFile="addNewFile"
            />
          </b-col>
          <b-col cols="7">
            <b-form-select
              v-model="selected"
              class="form-select-scooter-type"
              :options="options"
              value-field="item"
              text-field="name"
              disabled-field="notEnabled"
              @change="changeFirmWares"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <div
      v-if="!loading && firmwareData.length > 0"
      class="table mt-3"
    >
      <b-row class="table-data no-gutters">
        <b-col cols="2">
          File Name
        </b-col>
        <b-col cols="5">
          Scooter
        </b-col>
        <b-col cols="1">
          Size
        </b-col>
        <b-col cols="2">
          Last Modified
        </b-col>
        <b-col cols="2">
          Actions
        </b-col>
      </b-row>

      <b-row
        v-for="(firmware, index) in firmwareData"
        :key="index"
        class="table-data row-data no-gutters p-1"
      >
        <b-col cols="2">
          <feather-icon
            icon="FileIcon"
            size="24"
            color="#FF9F43"
          />

          {{ firmware.file_name }}
        </b-col>
        <b-col cols="5">
          {{ firmware.scooter_type }}
        </b-col>
        <b-col cols="1">
          {{ calculateSize(firmware.size) }}
        </b-col>
        <b-col cols="2">
          {{ moment(firmware.updated_at).format('DD MM Y') }}
        </b-col>
        <b-col
          cols="2"
          class="text-right"
        >
          <feather-icon
            icon="Trash2Icon"
            size="16"
            color="#818084"
            @click="deleteFile(index)"
          />

          <b-dropdown
            class="more-menu"
            dropleft
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="20"
                color="#818084"
              />
            </template>

            <span class="actions-menu-heading">The file belongs to</span>

            <b-dropdown-item
              v-for="(option, Index) in optionsWithout('All')"
              :key="Index"
              href="#"
              :class="option.item == firmware.scooter_type ? 'active' : ''"
              @click="changeFileScooterType(index, option.item)"
            >
              {{ option.item }}
            </b-dropdown-item>

          </b-dropdown>
        </b-col>
      </b-row>
    </div>
    <div
      v-else-if="!loading"
      class="no-files-available"
    >
      No files available for  {{ selected }}  Model
    </div>

    <div
      v-if="loading"
      class="loader-wrapper"
    >
      <b-col class="d-flex justify-content-center">
        <b-spinner label="Loading..." />
      </b-col>
    </div>
  </b-card>
</template>

<script>
import AddNewFile from '@/views/settings/components/AddNewFile.vue'
import Moment from 'moment'

export default {
  components: {
    AddNewFile,
  },
  data() {
    return {
      selected: null,
      moment: Moment,
      options: [],
      firmwareData: [],
      loading: false,
      emailTemplates: [],
    }
  },
  mounted() {
    this.getFirmWares()
    this.getScooterTypes()
  },

  methods: {
    deleteFile(index) {
      this.$swal({
        title: 'Delete file?',
        text: 'Would you like to delete this file?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#FF0000',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.value) {
          this.$http.delete(this.$resources.firmwares.destroy.replace(':id', this.firmwareData[index].uuid)).then(() => {
            this.firmwareData.splice(index, 1)
            this.$notify({ type: 'success', title: 'Delete file', text: 'File deleted sucessfully' })
          })
        }
      })
    },
    addNewFile(selected) {
      this.getFirmWares(selected)
    },
    changeFirmWares() {
      this.getFirmWares(this.selected)
    },
    getFirmWares(scooterType = null) {
      this.loading = true
      this.selected = scooterType !== null ? scooterType : 'All'
      this.$http.get(this.$resources.firmwares.index, scooterType !== 'All' ? {
        params: {
          scooter_type: scooterType,
        },
      } : null).then(response => {
        const { data: { data } } = response
        this.firmwareData = data.rows
        this.loading = false
        this.selected = scooterType || this.selected
      })
    },
    getScooterTypes() {
      this.$http.get(this.$resources.firmwares.scooterTypes).then(response => {
        const { data: { data } } = response
        this.options = data.rows.map(element => ({ item: element.type, name: element.type }))
        this.options.unshift({ item: 'All', name: 'All' })
      })
    },
    changeFileScooterType(index, option) {
      this.$http.post(this.$resources.firmwares.changeType.replace(':id', this.firmwareData[index].uuid), {
        scooter_type: option,
      }).then(() => {
        if (option !== this.firmwareData[index].scooter_type) {
          this.firmwareData[index].scooter_type = option
          if (this.selected !== 'All') {
            const newFirmwares = this.firmwareData.filter(firmware => firmware.scooter_type !== option)
            this.firmwareData = newFirmwares
          }
        }
      })
    },
    optionsWithout(optionToRemove) {
      return this.options.filter(option => option.item !== optionToRemove)
    },
    calculateSize(sizeInBytes) {
      let size = sizeInBytes
      const fSExt = ['Bytes', 'KB', 'MB', 'GB']
      let i = 0
      while (size > 900) {
        size /= 1024
        i += 1
      }
      const exactSize = `${Math.round(size * 100) / 100} ${fSExt[i]}`
      return exactSize
    },

  },
}
</script>

  <style lang="scss">
  .configurator-page {
    .table-data {
      cursor: pointer;
      font-family: Montserrat;
      color: #000000;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      text-transform: uppercase;
      margin-top: 10px;
      &.row-data {
        font-size: 13px;
        font-weight: 500;
        border: 1px solid #ebe9f1;
        border-radius: 10px;
        text-transform: none;
      }

      .more-menu {
        margin-top: -20px;
        margin-bottom: -8px;
        li{
          &.active {
            background-color: black !important;
            a {
              color: #ffffff;
            }
          }
        }
      }

      .dropdown-menu {
        text-align: right;
        position: absolute !important;
        top: 40px !important;
        left: 50px !important;
        will-change: transform !important;
        .dropdown-item {
          line-height: 10px;
        }
      }

      .actions-menu-heading {
        padding-right: 10px;
      }
    }

    .page-title {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 18px;
      color: #000000;
      line-height: 15px;
    }

    .form-select-scooter-type {
      font-family: Montserrat;
      font-weight: 700 !important;
      font-size: 15px !important;
      line-height: 15px !important;
    }

    .btn-gray {
      background-color: #dddddd !important;
      border: 0;
      color: #1d1d1d !important;
      font-size: 14px;
      &:hover {
        box-shadow: none !important;
        background-color: #666666 !important;
        color: #ffffff !important;
      }
    }

    .btn-group {
      border: none !important;
    }

    .configurator-files {
      .card-body {
        min-height: 670px;
      }
      .no-files-available {
        min-height: 670px;
        justify-content: center;
        align-items: center;
        display: flex;
      }
      .loader-wrapper {
        min-height: 670px;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }

  }
  </style>
